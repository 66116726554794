import { PokemonType } from "../helpers/types"

export type TypeGroupResult = Record<string, TypeEffective>

export type TypeEffectiveResult = Record<"offense" | "defense", Partial<TypeEffective>>
export type TypeEffective = Record<PokemonType, number>
export type TypeGroupByFunc = (acc: TypeGroupResult, value: [string, number | undefined]) => TypeGroupResult

export enum AttackEffectiveLabel {
    SuperEffective = "Super Effective", // 2.56
    Effective = "Effective", // 1.6
    Normal = "Normal", // 1
    NotEffective = "Not Effective", // .625
    Immune = "Immune", //.39
}

export enum DefenderEffectiveLabel {
    Weakness = "Weakness", // 2.56
    Normal = "Normal", // 1
    Resistance = "Resistance", // .625
    Immune = "Immune", //.39
}

export const calculateTypeEffectiveness = (types: Array<string>) => {
    const [typeOne, typeTwo] = types.map(type => type.toLowerCase() as PokemonType)
    const result: TypeEffectiveResult = {
        offense: {},
        defense: {}
    }
    const resultTwo: TypeEffectiveResult = {
        offense: {},
        defense: {}
    }
    if (typeOne) {
        result.offense = calculateOffense(typeOne)
        result.defense = calculateDefense(typeOne)
    }
    if (typeTwo) {
        resultTwo.offense = calculateOffense(typeTwo)
        resultTwo.defense = calculateDefense(typeTwo)
        const combined = combineTypeResults(result, resultTwo)
        result.offense = combined.offense
        result.defense = combined.defense
    }
    return result
}

const combineTypeResults = (result: TypeEffectiveResult, resultTwo: TypeEffectiveResult) => {
    const offense = combineTypeResult(result.offense, resultTwo.offense)
    const defense = combineTypeResult(result.defense, resultTwo.defense)
    return { offense, defense }
}

const combineTypeResult = (category: Partial<TypeEffective>, categoryTwo: Partial<TypeEffective>) => {
    const temp: Partial<TypeEffective> = {}
    Object.entries(category).forEach((entry, index) => {
        const key = entry[0] as PokemonType
        if (key in categoryTwo && entry[1]) {
            const value = categoryTwo?.[key] || 1
            temp[key] = roundTypeMultiplier(entry[1] * value)
        }
    })
    return temp
}

const calculateOffense = (type: PokemonType) => {
    const offense: Partial<TypeEffective> = {}
    Object.entries(typeEffectivness[type]).map(([defenderType, value]) => {
        offense[defenderType as PokemonType] = roundTypeMultiplier(value)
    })
    return offense
}

const calculateDefense = (type: PokemonType) => {
    const defense: Partial<TypeEffective> = {}
    Object.entries(typeEffectivness).map(([attacterType, values]) => {
        const value = values[type]
        defense[attacterType as PokemonType] = roundTypeMultiplier(value)
    })
    return defense
}

const roundTypeMultiplier = (multiplier: number) => {
    return Math.round(multiplier * 100) / 100
}

// export const getEffectivenessLabel = (value: number) => {
//     if (value > 2.56) {
//         return EffectiveLabel.SuperEffective
//     } else if (value >= 1.6) {
//         return EffectiveLabel.Effective
//     } else if (value >= 1) {
//         return EffectiveLabel.Normal
//     } else if (value >= .625) {
//         return EffectiveLabel.NotEffective
//     } else if (value <= .4) {
//         return EffectiveLabel.Immune
//     }
// }

export const defenseGroupBy: TypeGroupByFunc = (acc, value) => {
    if (!value[1]) {
        return acc
    }
    let key;

    if (value[1] >= 1.6) {
        key = 'weaknesss'
    } else if (value[1] >= 1) {
        key = 'neutral'
    } else if (value[1] <= .65) {
        key = 'resistance'
    }
    if (key) {
        // @ts-ignore
        const weakness = Object.assign(acc[key] || {}, { [value[0]]: value[1] })
        // @ts-ignore
        acc[key] = weakness
    }
    return acc;
}

export const defenseSortBy = 'weakness resistance neutral'

export const attackGroupBy: TypeGroupByFunc = (acc, value) => {
    if (!value[1]) {
        return acc
    }
    let key;

    if (value[1] >= 1.6) {
        key = 'super effective'
    } else if (value[1] >= 1) {
        key = 'neutral'
    } else if (value[1] <= .65) {
        key = 'not very effective'
    }
    if (key) {
        // @ts-ignore
        const weakness = Object.assign(acc[key] || {}, { [value[0]]: value[1] })
        // @ts-ignore
        acc[key] = weakness
    }
    return acc;
}

export const attackSortBy = 'super effective not very effective neutral'

export const typeEffectivness: Record<PokemonType, TypeEffective> = {
    "bug":
    {
        "bug": 1, "dark": 1.6, "dragon": 1, "electric": 1, "fairy": 0.625, "fighting": 0.625, "fire": 0.625, "flying": 0.625, "ghost": 0.625, "grass": 1.6, "ground": 1, "ice": 1, "normal": 1, "poison": 0.625, "psychic": 1.6, "rock": 1, "steel": 0.625, "water": 1
    },
    "dark":
    {
        "bug": 1, "dark": 0.625, "dragon": 1, "electric": 1, "fairy": 0.625, "fighting": 0.625, "fire": 1, "flying": 1, "ghost": 1.6, "grass": 1, "ground": 1, "ice": 1, "normal": 1, "poison": 1, "psychic": 1.6, "rock": 1, "steel": 1, "water": 1
    },
    "dragon":
    {
        "bug": 1, "dark": 1, "dragon": 1.6, "electric": 1, "fairy": 0.390625, "fighting": 1, "fire": 1, "flying": 1, "ghost": 1, "grass": 1, "ground": 1, "ice": 1, "normal": 1, "poison": 1, "psychic": 1, "rock": 1, "steel": 0.625, "water": 1
    },
    "electric":
    {
        "bug": 1, "dark": 1, "dragon": 0.625, "electric": 0.625, "fairy": 1, "fighting": 1, "fire": 1, "flying": 1.6, "ghost": 1, "grass": 0.625, "ground": 0.390625, "ice": 1, "normal": 1, "poison": 1, "psychic": 1, "rock": 1, "steel": 1, "water": 1.6
    },
    "fairy":
    {
        "bug": 1, "dark": 1.6, "dragon": 1.6, "electric": 1, "fairy": 1, "fighting": 1.6, "fire": 0.625, "flying": 1, "ghost": 1, "grass": 1, "ground": 1, "ice": 1, "normal": 1, "poison": 0.625, "psychic": 1, "rock": 1, "steel": 0.625, "water": 1
    },
    "fighting":
    {
        "bug": 0.625, "dark": 1.6, "dragon": 1, "electric": 1, "fairy": 0.625, "fighting": 1, "fire": 1, "flying": 0.625, "ghost": 0.390625, "grass": 1, "ground": 1, "ice": 1.6, "normal": 1.6, "poison": 0.625, "psychic": 0.625, "rock": 1.6, "steel": 1.6, "water": 1
    },
    "fire":
    {
        "bug": 1.6, "dark": 1, "dragon": 0.625, "electric": 1, "fairy": 1, "fighting": 1, "fire": 0.625, "flying": 1, "ghost": 1, "grass": 1.6, "ground": 1, "ice": 1.6, "normal": 1, "poison": 1, "psychic": 1, "rock": 0.625, "steel": 1.6, "water": 0.625
    },
    "flying":
    {
        "bug": 1.6, "dark": 1, "dragon": 1, "electric": 0.625, "fairy": 1, "fighting": 1.6, "fire": 1, "flying": 1, "ghost": 1, "grass": 1.6, "ground": 1, "ice": 1, "normal": 1, "poison": 1, "psychic": 1, "rock": 0.625, "steel": 0.625, "water": 1
    },
    "ghost":
    {
        "bug": 1, "dark": 0.625, "dragon": 1, "electric": 1, "fairy": 1, "fighting": 1, "fire": 1, "flying": 1, "ghost": 1.6, "grass": 1, "ground": 1, "ice": 1, "normal": 0.390625, "poison": 1, "psychic": 1.6, "rock": 1, "steel": 1, "water": 1
    },
    "grass":
    {
        "bug": 0.625, "dark": 1, "dragon": 0.625, "electric": 1, "fairy": 1, "fighting": 1, "fire": 0.625, "flying": 0.625, "ghost": 1, "grass": 0.625, "ground": 1.6, "ice": 1, "normal": 1, "poison": 0.625, "psychic": 1, "rock": 1.6, "steel": 0.625, "water": 1.6
    },
    "ground":
    {
        "bug": 0.625, "dark": 1, "dragon": 1, "electric": 1.6, "fairy": 1, "fighting": 1, "fire": 1.6, "flying": 0.390625, "ghost": 1, "grass": 0.625, "ground": 1, "ice": 1, "normal": 1, "poison": 1.6, "psychic": 1, "rock": 1.6, "steel": 1.6, "water": 1
    },
    "ice":
    {
        "bug": 1, "dark": 1, "dragon": 1.6, "electric": 1, "fairy": 1, "fighting": 1, "fire": 0.625, "flying": 1.6, "ghost": 1, "grass": 1.6, "ground": 1.6, "ice": 0.625, "normal": 1, "poison": 1, "psychic": 1, "rock": 1, "steel": 0.625, "water": 0.625
    },
    "normal":
    {
        "bug": 1, "dark": 1, "dragon": 1, "electric": 1, "fairy": 1, "fighting": 1, "fire": 1, "flying": 1, "ghost": 0.390625, "grass": 1, "ground": 1, "ice": 1, "normal": 1, "poison": 1, "psychic": 1, "rock": 0.625, "steel": 0.625, "water": 1
    },
    "poison":
    {
        "bug": 1, "dark": 1, "dragon": 1, "electric": 1, "fairy": 1.6, "fighting": 1, "fire": 1, "flying": 1, "ghost": 0.625, "grass": 1.6, "ground": 0.625, "ice": 1, "normal": 1, "poison": 0.625, "psychic": 1, "rock": 0.625, "steel": 0.390625, "water": 1
    },
    "psychic":
    {
        "bug": 1, "dark": 0.390625, "dragon": 1, "electric": 1, "fairy": 1, "fighting": 1.6, "fire": 1, "flying": 1, "ghost": 1, "grass": 1, "ground": 1, "ice": 1, "normal": 1, "poison": 1.6, "psychic": 0.625, "rock": 1, "steel": 0.625, "water": 1
    },
    "rock":
    {
        "bug": 1.6, "dark": 1, "dragon": 1, "electric": 1, "fairy": 1, "fighting": 0.625, "fire": 1.6, "flying": 1.6, "ghost": 1, "grass": 1, "ground": 0.625, "ice": 1.6, "normal": 1, "poison": 1, "psychic": 1, "rock": 1, "steel": 0.625, "water": 1
    },
    "steel":
    {
        "bug": 1, "dark": 1, "dragon": 1, "electric": 0.625, "fairy": 1.6, "fighting": 1, "fire": 0.625, "flying": 1, "ghost": 1, "grass": 1, "ground": 1, "ice": 1.6, "normal": 1, "poison": 1, "psychic": 1, "rock": 1.6, "steel": 0.625, "water": 0.625
    },
    "water":
    {
        "bug": 1, "dark": 1, "dragon": 0.625, "electric": 1, "fairy": 1, "fighting": 1, "fire": 1.6, "flying": 1, "ghost": 1, "grass": 0.625, "ground": 1.6, "ice": 1, "normal": 1, "poison": 1, "psychic": 1, "rock": 1.6, "steel": 1, "water": 0.625
    }
}