import React, { FC, useEffect, useState } from "react"

import { TypeEffective, TypeGroupByFunc, TypeGroupResult } from "../../models/type-effectiveness"
import { PokemonType } from "../../helpers/types"
import { capitalize } from "../../helpers/helpers"

import Box from "../box"
import Heading from "../heading"
import TypeTag from "../pokemon/type-tag"

interface Props {
    results: Partial<TypeEffective>;
    groupBy: TypeGroupByFunc;
    sortBy: string;
}

const TypeEffectivenessResults: FC<Props> = ({ results, groupBy, sortBy }) => {
    const [grouped, setGrouped] = useState<TypeGroupResult>({})

    const compare = (a: string, b: string) => sortBy.indexOf(a) - sortBy.indexOf(b)

    useEffect(() => {
        const groupedResults = Object.entries(results).reduce(groupBy, {})
        setGrouped(groupedResults)
    }, [results])

    return (
        <Box>
            {
                results && Object.entries(grouped).sort(([a], [b]) => compare(a, b)).map(([category, types]) => (
                    <Box key={`type-result-${category}`}>
                        <Heading as="h2" fontSize={3} mt={5} mb={1}>{capitalize(category)}</Heading>
                        <Box display="flex" flexWrap="wrap">
                            {/* @ts-ignore */}
                            {Object.entries(types)
                                .sort((a, b) => b[1] - a[1])
                                .map(([entry, multiplier], index) => (
                                    <TypeTag
                                        key={`type-result-${category}-${index}`}
                                        type={entry as PokemonType}
                                        multiplier={multiplier}
                                        mt={2}
                                        mr={2} />
                                ))}
                        </Box>
                    </Box>
                )
                )
            }
        </Box>
    )
}

export default TypeEffectivenessResults