import styled from "styled-components";
import { backgroundColor, BackgroundColorProps, borderColor, BorderColorProps, color, ColorProps, fontSize, FontSizeProps, space, SpaceProps, flexbox, FlexboxProps, display, DisplayProps, borderRadius, BorderRadiusProps } from "styled-system";

interface Props extends SpaceProps, FontSizeProps, ColorProps, BackgroundColorProps, BorderColorProps, FlexboxProps, DisplayProps, BorderRadiusProps {}

const Label = styled.label<Props>`
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: 1;

    ${space}
    ${fontSize}
    ${backgroundColor}
    ${color}
    ${borderColor}
    ${display}
    ${flexbox}
    ${borderRadius}
`

export default Label