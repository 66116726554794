import React, { FC, useEffect } from "react"

import { typeEffectivness } from "../../models/type-effectiveness"
import { getTypeColor } from "../../helpers/types"
import Box from "../box"
import Input from "../form/input"
import Label from "../form/label"
import LabelSelection from "../form/labelSelection"

interface Props {
    index?: number;
    maxSelectionCount?: number;
    selections?: Array<string>;
    onSelection(selection: Array<string>): void
}

const TypeSelector: FC<Props> = ({ index = 1, maxSelectionCount = 2, selections = [], onSelection }) => {

    useEffect(() => {
        if (selections.length > maxSelectionCount) {
            const tempSelection = Array.from(selections)
            tempSelection.splice(tempSelection.length - maxSelectionCount)
            onSelection(tempSelection)
        }
    }, [maxSelectionCount])

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const tempSelection = Array.from(selections)
        const {currentTarget: {value}} = evt

        if (tempSelection.includes(value)) {
            // deselect
            const index = tempSelection.indexOf(value)
            tempSelection.splice(index, 1)
        } else if (tempSelection.length < maxSelectionCount) {
            // add
            tempSelection.push(value)
        } else if (tempSelection.length === maxSelectionCount) {
            // replace
            tempSelection.pop()
            tempSelection.push(value)
        }
        onSelection(tempSelection)
    }

    return (
        <Box display="grid" gridTemplateColumns='1fr 1fr 1fr 1fr' gridTemplateRows='auto' gridGap={3}>
            {Object.keys(typeEffectivness).map((type) => (
                <LabelSelection key={`type-${index}-${type}`}>
                    <Input
                        id={`type-${index}-${type}`}
                        type="checkbox"
                        name={`type-${index}`}
                        value={type}
                        checked={selections?.includes(type)}
                        onChange={handleChange} />
                    <Label
                        py={3}
                        px={4}
                        htmlFor={`type-${index}-${type}`}
                        fontSize={2}
                        borderColor={getTypeColor(type)}
                        backgroundColor={selections?.includes(type) ? getTypeColor(type) : 'white'}>
                        {type}
                    </Label>
                </LabelSelection>
            ))}
        </Box>
    )
}

export default TypeSelector