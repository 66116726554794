import { FC } from "react";
import styled from "styled-components";
import { flexbox, FlexboxProps, space, SpaceProps } from "styled-system";

interface Props extends SpaceProps, FlexboxProps {}

const LabelSelection = styled.div<Props>`
    input {
        display: none;
    }

    label {
        display: block;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        font-weight: bold;
        text-transform: capitalize;
    }

    input:checked + label {
        color: white;
    }
    ${space}
    ${flexbox}
`

export default LabelSelection