import React, { FC, useEffect, useState } from "react"
import { PageProps } from "gatsby"

import { attackGroupBy, attackSortBy, defenseGroupBy, defenseSortBy, typeEffectivness } from '../models/type-effectiveness'
import { TypeEffectiveResult, calculateTypeEffectiveness } from '../models/type-effectiveness'

import SEO from "../components/seo"

import Heading from "../components/heading"
import Card from "../components/card"
import Box from "../components/box";
import Wrapper from "../containers/wrapper"
import Input from "../components/form/input"
import TypeEffectivenessResults from "../components/type-effectiveness/type-effectiveness-results"
import TypeSelector from "../components/type-effectiveness/type-selector"
import Label from "../components/form/label"

interface Props extends PageProps {}

const TypeEffectiveness: FC<Props> = ({ location }) => {
  const [types, setTypes] = useState<Array<string>>([])
  const [value, setValue] = useState<string>('defender')
  const [typeParams, setTypeParams] = useState<Array<string>>()

  const [typeResults, setTypeResults] = useState<TypeEffectiveResult>()

  useEffect(() => {
    const result = types.length > 0 ?
      calculateTypeEffectiveness(types) :
      undefined
    setTypeResults(result)
  }, [types])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const searchTypes = urlParams.get('types')?.split(',') || []
    // Filter only existing types and max count of 2
    const filteredTypes = searchTypes.filter(type => type in typeEffectivness).slice(0,2)
    setTypes(filteredTypes)
  }, [location])

  return (
    <Wrapper>
      <SEO title={`Pokemon Go Type Effectiveness Calculator`} />

      <Heading as="h1" fontSize={7} mb={5}>Type Effectiveness Calculator</Heading>

      <Box mt={6} mb={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" fontSize={4} mb={2}>Pokemon Type</Heading>
          <Heading as="h3" fontSize={2} mb={0} fontWeight="normal" color="grey.5">
            Select
            {value === 'defender' ? ` 1 or 2 ` : ` 1 `}
            Pokemon type
          </Heading>
        </Box>
        <Box display="flex">
          <Box mr={4}>
            <Input
              id="type-selection-defender"
              type="radio"
              name="type-selection"
              checked={value === 'defender'}
              value="defender"
              onChange={() => setValue('defender')} />
            <Label fontSize={3} ml={2} htmlFor="type-selection-defender">Defender</Label>
          </Box>
          <Box>
            <Input
              id="type-selection-attacker"
              type="radio"
              name="type-selection"
              checked={value === 'attacker'}
              value="attacker"
              onChange={() => setValue('attacker')} />
            <Label fontSize={3} ml={2} htmlFor="type-selection-attacker">Attacker</Label>
          </Box>
        </Box>
      </Box>
      <Card variant="flat" p={4} mb={6}>
        <TypeSelector
          selections={types}
          maxSelectionCount={value === "defender" ? 2 : 1}
          onSelection={(selection) => setTypes(selection)} />
      </Card>

      { typeResults && <>
        {/* <Heading as="h2" fontSize={4} mt={4} mb={3}>{capitalize(value)}</Heading> */}
        <TypeEffectivenessResults
          groupBy={value === "defender" ? defenseGroupBy : attackGroupBy}
          sortBy={value === "defender" ? defenseSortBy : attackSortBy}
          results={value === "defender" ? typeResults.defense : typeResults.offense} />
      </>}

    </Wrapper>
  )
}

export default TypeEffectiveness
